@font-face {
  font-family: "soehne";
  src: url("fonts/soehne-web-buch.woff2") format("woff2"),
    url("fonts/soehne-web-buch.woff") format("woff"),
    url("fonts/soehne-web-buch.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "soehne";
  src: url("fonts/soehne-web-buch-kursiv.woff2") format("woff2"),
    url("fonts/soehne-web-buch-kursiv.woff") format("woff"),
    url("fonts/soehne-web-buch-kursiv.eot") format("eot");
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "soehne", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  border: 1px solid black;
  border-top: none;
  margin-bottom: 28px;
}

p {
  margin: 14px 0;
}
